import React from 'react';
import {useAuth0} from "@auth0/auth0-react";

function App() {
    const { loginWithRedirect } = useAuth0();
  return (
    <div className="App">
      <header className="App-header">
        <img src={'/assets/jigmail-short.png'} className="App-logo" alt="logo" width={120} />
          <h1 className="App-title">There is where React will shine</h1>
          <button style={{color: 'blue',
              backgroundColor: 'lightgray',
              padding: '10px',
              borderRadius: '5px'}} onClick={() => loginWithRedirect()}>Log In</button>
      </header>
    </div>
  );
}

export default App;
