import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider clientId={'M9YtxGFiGYyov5JxdEOBWv5Vr4wnEvAL'} domain={'jigmail.au.auth0.com'}>
    <App />
      </Auth0Provider>
  </React.StrictMode>
);
